<template>
  <div>
    <!-- ADD Work Type FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Vendor">
          <form>
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Name *</h6>
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  name="Vendor Name"
                  v-model="form.vendor_name"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Name')"
                    >{{ errors.first("Vendor Name") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>PAN Number * (eg.ABCDE1234A)</h6>
                <vs-input
                  v-validate="'required|alpha_num|min:10|max:10'"
                  name="Vendor Pan"
                  v-model="form.vendor_pan"
                  @input="form.vendor_pan = form.vendor_pan.toUpperCase()"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Pan')"
                    >{{ errors.first("Vendor Pan") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Aadhar *</h6>
                <vs-input
                  v-validate="'required|numeric|max:12|min:12'"
                  name="Vendor Aadhar"
                  type="number"
                  v-model="form.vendor_aadhar"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Aadhar')"
                    >{{ errors.first("Vendor Aadhar") }}</span
                  >
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-4 mb-2">
                <h6>GST *</h6>
                <vs-input
                  v-validate="'required|max:15|min:15'"
                  name="vendor GST"
                  v-model="form.vendor_gst"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('vendor GST')"
                    >{{ errors.first("vendor GST") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Pincode *</h6>
                <vs-input
                  type="text"
                  v-validate="'required|max:10'"
                  name="Vendor Pincode"
                  v-model="form.vendor_pincode"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Pincode')"
                    >{{ errors.first("Vendor Pincode") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Mob Number *</h6>
                <vs-input
                  type="number"
                  v-validate="'required'"
                  name="Vendor Number"
                  v-model="form.vendor_number"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Number')"
                    >{{ errors.first("Vendor Number") }}</span
                  >
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-4 mb-2">
                <h6>Type of Vendor *</h6>
                <vs-input
                  v-validate="'required'"
                  name="Type of Vendor"
                  v-model="form.type_of_vendor"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Type of Vendor')"
                    >{{ errors.first("Type of Vendor") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Services *</h6>
                <vs-input
                  v-validate="'required'"
                  name="Vendor Services"
                  v-model="form.vendor_services"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Services')"
                    >{{ errors.first("Vendor Services") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Status</h6>
                <vs-switch v-model="switch1" />
              </div>
            </div>

            <div class="row">
              <div class="col-4 mb-2">
                <h6>Address *</h6>
                <vs-textarea
                  v-model="form.vendor_address"
                  class="mb-0"
                  rows="4"
                />
              </div>
            </div>
          </form>

          <div class="vx-col">
            <div class="excel-btn-1">
              <vx-tooltip text="Template Excel" position="left">
                <vs-button
                  type="border"
                  class="py-2"
                  @click.native="
                    onClickSampleDownload(
                      excelurl + `/v2/contractVendor/excel/sample`
                    )
                  "
                >
                  <img
                    class="d-inline-flex"
                    src="@/assets/images/icons/excel.svg"
                    height="20"
                    width="20"
                  />
                </vs-button>
              </vx-tooltip>
            </div>

            <div class="excel-btn-2">
              <vx-tooltip text="Sync Vendor From Tally" position="left">
                <vs-button class="px-4 mr-2" @click.native="syncVendor()">
                  <span class="d-inline-flex pr-5 text-white"
                    >Sync Vendor From Tally</span
                  >
                </vs-button>
              </vx-tooltip>
            </div>
          </div>

          <!-- SUBMIT BUTTON -->
          <div class="vx-row">
            <div class="vx-col mx-auto">
              <vs-button
                :disabled="submitStatus"
                type="filled"
                @click.prevent="submitForm"
                class="mr-3"
                >Submit</vs-button
              >

              <vs-button
                color="warning"
                type="border"
                class
                @click.prevent="clearForm"
                >Reset</vs-button
              >
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import ContractService from "@/services/ContractService.js";
import CompanyService from "@/services/companyService.js";
import axios from "axios";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import { BASEURL } from "@/config/index.js";
import ChatLogVue from "../chat/ChatLog.vue";
export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
  },
  data() {
    return {
      excelurl: BASEURL(),
      submitStatus: true,
      switch1: true,
      designation_description: "",
      form: {
        vendor_name: "",
        vendor_pan: "",
        vendor_aadhar: "",
        vendor_gst: "",
        vendor_address: "",
        vendor_pincode: "",
        vendor_number: "",
        type_of_vendor: "",
        vendor_services: "",
        vendor_isactive: 1,
      },
      notification_days: 0,

      value: [],
      companies: [],
      User: "Select User",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
      organization_id: "",
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    syncVendor() {
      this.$vs.loading();
      axios
        .get(`${BASEURL}/v2/tally/syncVendor`)
        .then((response) => {
          this.$vs.notify({
            // title: "Duplicate entries have not been imported.",
            title: "Updated!",
            text: "Sync Successfully",
            // time:8000,
            time: 2000,
            iconPack: "feather",
            icon: "check_box",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    panValid() {
      var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
      console.log(this.form.vendor_pan, regpan.test(this.form.vendor_pan));
      if (regpan.test(this.form.vendor_pan)) {
        return true;
      } else {
        return false;
      }
    },
    uploadExcel: function (event) {
      let that = this;
      that.$vs.loading();
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      // console.log(that.excelurl + `/v2/users/import/excel?organization_id=${that.organization_id}`);
      axios
        .post(
          that.excelurl +
            `/v2/contractVendor/import/excel?organization_id=${that.organization_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const { data } = response;
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          if (
            data &&
            data["error"] &&
            data.error["message"] &&
            data.error.message.length > 0
          ) {
            that.$vs.notify({
              // title: "Duplicate entries have not been imported.",
              title: "Error!",
              text: data.error.message || "Error",
              // time:8000,
              time: 35000,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          } else {
            that.$vs.notify({
              title: "Updated!",
              text: data.data.message || "Updated",
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            eventBus.$emit("conrefreshTableVendor", data);
          }
          that.$vs.loading.close();

          eventBus.$emit("conrefreshTableVendor", data);
        })
        .catch((error) => {
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";

          that.$vs.loading.close();
          that.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    uploadFromTally: function (event) {
      let that = this;
      that.$vs.loading();
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      // console.log(that.excelurl + `/v2/users/import/excel?organization_id=${that.organization_id}`);
      axios
        .post(
          that.excelurl +
            `/v2/contractVendor/importfromtally?organization_id=${that.organization_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const { data } = response;
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          if (
            data &&
            data["error"] &&
            data.error["message"] &&
            data.error.message.length > 0
          ) {
            that.$vs.notify({
              title: "Error!",
              text: data.error.message || "Error",
              time: 35000,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          } else {
            that.$vs.notify({
              title: "Updated!",
              text: data.data.message || "Updated",
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            eventBus.$emit("conrefreshTableVendor", data);
          }
          that.$vs.loading.close();

          eventBus.$emit("conrefreshTableVendor", data);
        })
        .catch((error) => {
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";

          that.$vs.loading.close();
          that.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onClickSampleDownload: function (URL) {
      window.open(URL, "_blank");
    },

    submitForm() {
      if (this.form.vendor_pan) {
        if (!this.panValid()) {
          this.$vs.notify({
            title: "Error!",
            text: "PAN Number is not Valid",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });

          return false;
        }
      }

      if (
        this.form.vendor_aadhar != "" &&
        this.form.vendor_aadhar.toString().length < 12
      ) {
        this.$vs.notify({
          title: "Error!",
          text: "Aadhar Number is not Valid",

          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });

        return false;
      }

      if (
        this.form.vendor_gst != "" &&
        this.form.vendor_gst.toString().length < 12
      ) {
        this.$vs.notify({
          title: "Error!",
          text: "GST Number is not Valid",

          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });

        return false;
      }

      if (this.form.vendor_name != "") {
        this.$vs.loading();

        this.submitStatus = false;
        let payload = {
          vendor_id: "",
          ...this.form,
          vendor_isactive: this.switch1 ? "1" : "2",
        };
        ContractService.addContractVendorNew(payload)
          .then((response) => {
            this.submitStatus = false;

            const { data } = response;
            this.$vs.loading.close();

            if (data.Status == true || data.Status == "true") {
              this.$vs.notify({
                title: "Created!",
                text: data.Message,

                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
              this.clearForm();
              setTimeout(() => {
                eventBus.$emit("conrefreshTableVendor", data);
              }, 1);
            } else {
              console.log("data :", data);
              this.$vs.notify({
                title: "Error!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
          })
          .catch((error) => {
            this.submitStatus = true;
            console.log("error :", error);
          });
        // if form have no errors
        // alert("form submitted!");
      }
      // });
    },
    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_isactive/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.designation_description = "";
      this.notification_days = 0;

      this.value = [];
      this.$validator.reset();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    this.organization_id = localStorage.getItem("OrganizationID");
    // this.getCompanyList();
    this.gridApi = this.gridOptions.api;
  },
};
</script>
