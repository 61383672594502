<template>
  <!-- v-if="checkPermission(['designation','PUT'])" -->
  <div>
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup
        :id="'popmodal' + params.data.vendor_id"
        class="holamundo editModal"
        title="Update Vendor"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="row mx-0">
              <!-- LEFT SIDE -->
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Vendor Name</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Vendor name"
                        v-model="form.vendor_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Vendor name')"
                        >{{ errors.first("Vendor name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">PAN</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Vendor Pan"
                        v-model="form.vendor_pan"
                        class="w-full"
                      />

                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Vendor Pan')"
                        >{{ errors.first("Vendor Pan") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Aadhar</h5>
                  </div>
                  <div class="col-7">
                    <vs-input
                      v-validate="'required'"
                      name="Vendor Aadhar"
                      v-model="form.vendor_aadhar"
                      class="w-full"
                    />

                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Vendor Aadhar')"
                      >{{ errors.first("Vendor Aadhar") }}</span
                    >
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">GST</h5>
                  </div>
                  <div class="col-7">
                    <vs-input
                      v-validate="'required'"
                      name="vendor GST"
                      v-model="form.vendor_gst"
                      class="w-full"
                    />

                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('vendor GST')"
                      >{{ errors.first("vendor GST") }}</span
                    >
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Pincode</h5>
                  </div>
                  <div class="col-7">
                    <vs-input
                      v-validate="'required'"
                      name="Vendor Pincode"
                      v-model="form.vendor_pincode"
                      class="w-full"
                    />

                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Vendor Pincode')"
                      >{{ errors.first("Vendor Pincode") }}</span
                    >
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Number</h5>
                  </div>
                  <div class="col-7">
                    <vs-input
                      v-validate="'required'"
                      name="Vendor Number"
                      v-model="form.vendor_number"
                      class="w-full"
                    />

                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Vendor Number')"
                      >{{ errors.first("Vendor Number") }}</span
                    >
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Type of Vendor</h5>
                  </div>
                  <div class="col-7">
                    <vs-input
                      v-validate="'required'"
                      name="Type of Vendor"
                      v-model="form.type_of_vendor"
                      class="w-full"
                    />

                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Type of Vendor')"
                      >{{ errors.first("Type of Vendor") }}</span
                    >
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Services</h5>
                  </div>
                  <div class="col-7">
                    <vs-input
                      v-validate="'required'"
                      name="Vendor Services"
                      v-model="form.vendor_services"
                      class="w-full"
                    />

                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Vendor Services')"
                      >{{ errors.first("Vendor Services") }}</span
                    >
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Address</h5>
                  </div>
                  <div class="col-7">
                    <vs-textarea
                      v-model="form.vendor_address"
                      class="mb-0"
                      rows="4"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <!-- :disabled="submitStatus" -->
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Update</vs-button
                >

                <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ContractService from "@/services/ContractService.js";
import companyService from "@/services/companyService.js";
export default Vue.extend({
  data() {
    return {
      switch1: true,
      submitStatus: true,
      form: {
        vendor_id: "",
        vendor_name: "",
        vendor_pan: "",
        vendor_aadhar: "",
        vendor_gst: "",
        vendor_address: "",
        vendor_pincode: "",
        vendor_number: "",
        type_of_vendor: "",
        vendor_isactive: "",
      },
      companies: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0,
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        if (this.count > 0) {
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true,
    },
  },
  beforeMount() {
    console.log(
      "this.form, this.params.data :",
      this.form,
      this.params.data.vendor_id
    );
    this.value = this.params.value;
    this.form = this.params.data;
    // this.getCompanyList();
  },
  beforeDestroy() {
    let _id = document.getElementById("popmodal" + this.params.data.vendor_id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    panValid() {
      var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
      console.log(this.form.vendor_pan, regpan.test(this.form.vendor_pan));
      if (regpan.test(this.form.vendor_pan)) {
        return true;
      } else {
        return false;
      }
    },
    showModal() {
      this.popupActive = true;
    },
    submitForm() {
      // this.$validator.validateAll().then((result) => {

      if (!this.panValid()) {
        this.$vs.notify({
          title: "Error!",
          text: "PAN Number is not Valid",

          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });

        return false;
      }

      if (
        this.form.vendor_aadhar != "" &&
        this.form.vendor_aadhar.toString().length < 12
      ) {
        this.$vs.notify({
          title: "Error!",
          text: "Aadhar Number is not Valid",

          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });

        return false;
      }

      if (
        this.form.vendor_gst != "" &&
        this.form.vendor_gst.toString().length < 12
      ) {
        this.$vs.notify({
          title: "Error!",
          text: "GST Number is not Valid",

          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });

        return false;
      }

      if (this.form.vendor_name != "") {
        this.$vs.loading();
        this.submitStatus = true;
        let id = this.form.vendor_id;
        let payload = {
          vendor_name: this.form.vendor_name,
          vendor_pan: this.form.vendor_pan,
          vendor_aadhar: this.form.vendor_aadhar,
          vendor_gst: this.form.vendor_gst,
          vendor_address: this.form.vendor_address,
          vendor_pincode: this.form.vendor_pincode,
          vendor_number: this.form.vendor_number,
          type_of_vendor: this.form.type_of_vendor,
          vendor_services: this.form.vendor_services,
          vendor_isactive: this.params.data.vendor_isactive,
        };

        ContractService.editContractVendorNew(payload, id)
          .then((response) => {
            this.$vs.loading.close();
            this.submitStatus = false;
            const { data } = response;
            this.popupActive = false;
            if (data.Status == true || data.Status == "true") {
              this.$vs.notify({
                title: "Updated!",
                text: data.Message,

                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
              setTimeout(() => {
                eventBus.$emit("conrefreshTableVendor", data);
              }, 1);
              this.clearForm();
            } else {
              this.$vs.loading.close();
              console.log("data :", data);
              this.$vs.notify({
                title: "Error!",
                text: data.Message,

                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.submitStatus = true;
            console.log("error :", error);
          });
        // if form have no errors
        // alert("form submitted!");
      }
    },

    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },

  mounted() {},
});
</script>
<style>
</style>
