<!-- =========================================================================================
    File Name: ChatLog.vue
    Description: Chat Application - Log of chat
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<!-- hasSentPreviousMsg -->
<template>
  <div id="component-chat-log" class="m-2 md:m-8">
    <div v-for="(msg, index) in chatData" class="msg-grp-container" :key="index">
      <div class="flex items-start" :class="[{'flex-row-reverse' : msg.user_name == 'me'}]">
        <vs-avatar
          size="40px"
          class="m-0 flex-shrink-0"
          :class="msg.user_name == 'me' ? 'sm:ml-5 ml-3' : 'sm:mr-5 mr-3'"
          :src="msg.user_pic+'?token='+tempToken"
        ></vs-avatar>
        <div
          class="msg break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg max-w-sm"
          :class="{'bg-primary-gradient text-white': !msg.isSent, 'border border-solid border-grey-light bg-white': msg.isSent}"
        >
          <span>{{ msg.msg }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contacts from "./contacts";

export default {
  props: {
    chatFeed: {
      type: Array
    },
    userId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      tempToken:window.localStorage.getItem("TempToken"),
      contacts: contacts
    };
  },
  computed: {
    chatData() {
      console.log("this.chatFeed :", this.chatFeed);
      return this.chatFeed;
    }
    // chatData() {
    //   return this.$store.getters["chat/chatDataOfUser"](this.userId);
    // },
    // contactIndex() {
    //   return contacts.findIndex(contact => contact.id == this.userId);
    // },
    // userImg() {
    //   if (this.contactIndex !== -1) return this.contacts[this.contactIndex].img;
    // },
    // activeUserImg() {
    //   return this.$store.state.AppActiveUser.img;
    // },
    // senderImg() {
    //   return isSentByActiveUser => {
    //     if (isSentByActiveUser)
    //       return require(`@/assets/images/portrait/small/${this.$store.state.AppActiveUser.img}`);
    //     else
    //       return require(`@/assets/images/portrait/small/${this.contacts[this.contactIndex].img}`);
    //   };
    // },
    // hasSentPreviousMsg() {
    //   return (last_sender, current_sender) => last_sender == current_sender;
    // }
  },
  methods: {
    isSameDay(time_to, time_from) {
      const date_time_to = new Date(Date.parse(time_to));
      const date_time_from = new Date(Date.parse(time_from));
      return (
        date_time_to.getFullYear() === date_time_from.getFullYear() &&
        date_time_to.getMonth() === date_time_from.getMonth() &&
        date_time_to.getDate() === date_time_from.getDate()
      );
    },
    toDate(time) {
      const locale = "en-us";
      const date_obj = new Date(Date.parse(time));
      const monthName = date_obj.toLocaleString(locale, {
        month: "short"
      });
      return date_obj.getDate() + " " + monthName;
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight;
      });
    }
  },
  updated() {
    this.scrollToBottom();
  },
  mounted() {
    this.scrollToBottom();
  }
};
</script>
