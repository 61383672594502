<!-- =========================================================================================
     File Name: designationList.vue
    Description: Designation
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- DESIGNATION LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>Vendor List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <!-- <ul class="list-inline px-4">
              <li class="list-inline-item font-semibold project-menu-dropdown">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                  <span class="flex cursor-pointer font-semibold">
                    Tally Sync Options : {{ selectedTallyOption }}
                    <feather-icon
                      icon="ChevronDownIcon"
                      svgClasses="h-4 w-4"
                      class="ml-1"
                    ></feather-icon>
                  </span>
                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item
                      selected
                      @click="onSelectTallySyncOption('All')"
                      >All</vs-dropdown-item
                    >
                    <vs-dropdown-item @click="onSelectTallySyncOption('Synced')"
                      >Synced</vs-dropdown-item
                    >
                    <vs-dropdown-item
                      @click="onSelectTallySyncOption('Unsynced')"
                      >Unsynced</vs-dropdown-item
                    >
                  </vs-dropdown-menu>
                </vs-dropdown>
              </li>
            </ul> -->

            <!-- Darshan | 23rd Oct, 2020 -->

            <!-- <vx-tooltip text="Sync to Tally">
              <vs-button class="px-4 mr-2" @click.native="syncVendor()">
                <span class="d-inline-flex pr-5 text-white">Sync to Tally 11</span>
                 <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
              </vs-button>
            </vx-tooltip> -->
            <div
              class="
                flex flex-wrap
                items-center
                justify-between
                ag-grid-table-actions-right
              "
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
            </div>
            <vx-tooltip text="Export Excel">
              <a
                :href="
                  excelurl +
                  `/v2/contractVendor/excel/download?organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
                "
                target="_blank"
                v-if="this.archive != 'archive'"
              >
                <vs-button
                  v-if="
                    this.permissionPageAccessArry.includes(
                      PermissionVariables.UserExport
                    ) || loginUserRole == 'Admin'
                  "
                  class="px-4"
                >
                  <span class="d-inline-flex pr-5 text-white">Export</span>
                  <img
                    src="@/assets/images/icons/export-excel.svg"
                    class="d-inline-flex"
                    height="20"
                    width="20"
                  />
                </vs-button>
              </a>
            </vx-tooltip>

            <vx-tooltip text="Export Archive Excel">
              <a
                :href="
                  excelurl +
                  `/v2/contractVendor/excel/download?organization_id=${organization_id}&token=${temp_token}&archive=1&search=${searchQuery}`
                "
                target="_blank"
                v-if="this.archive == 'archive'"
              >
                <vs-button class="px-4">
                  <span class="d-inline-flex pr-5 text-white">Export</span>

                  <img
                    src="@/assets/images/icons/export-excel.svg"
                    class="d-inline-flex"
                    height="20"
                    width="20"
                  />
                </vs-button>
              </a>
            </vx-tooltip>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="vendorsList"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import ContractService from "@/services/ContractService.js";

import companyService from "@/services/companyService.js";
import { BASEURL } from "@/config/index.js";
import SearchService from "@/services/searchService.js";
import vendorStatus from "./vendorStatus.vue";
import editVendor from "./editVendor.vue";
import vendorArchive from "./vendorArchive.vue";
import tallySyncStatus from "./tallySyncStatus.vue";
import multiSelectVendor from "./multiSelectVendor.vue";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
  },
  props: ["listType"],
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
      selectedVendorList: [],
      selectedTallyOption: "All",
      excelurl: BASEURL(),
      archive: this.listType,
      User: "Select User",
      designations: [],
      vendorsList: [],
      totalPages: "",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      columnDefs: [],
      contacts: contacts,
      organization_id: "",
      isTally: "",
    };
  },
  beforeMount() {
    eventBus.$on("conrefreshTableVendor", (e) => {
      this.getWorkTypeList(this.currentPage);
    });
    this.organization_id = localStorage.getItem("OrganizationID");
    this.isTally = localStorage.getItem("isTally");
    this.columnDefs = [
      // {
      //   headerName: "Sr.No.",
      //   field: "firstname",
      //   width: 150,
      //   filter: false,
      //   checkboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   headerCheckboxSelection: true
      // },
      // {
      //   headerName: "",
      //   field: "",
      //   filter: false,
      //   width: 100,
      //   suppressSizeToFit: true,
      //   cellRendererFramework: multiSelectVendor,
      // },
      {
        headerName: "Vendor Name",
        field: "vendor_name",
        filter: false,
        width: 300,
        suppressSizeToFit: true,
      },
      // ****
      {
        headerName: "PAN",
        field: "vendor_pan",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Aadhar",
        field: "vendor_aadhar",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },

      {
        headerName: "GST",
        field: "vendor_gst",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },

      {
        headerName: "Type of Vendor",
        field: "type_of_vendor",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      // ****
      {
        headerName: "Tally Sync",
        field: "",
        filter: false,
        width: 150,
        sortable: false,
        hide:
          (this.archive == "archive" ||
            !this.permissionPageAccessArry.includes(
              PermissionVariables.VendorEdit
            )) &&
          this.loginUserRole != "Admin"
            ? true
            : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: tallySyncStatus,
      },
      {
        headerName: "Status",
        field: "",
        filter: false,
        width: 150,
        sortable: false,
        hide:
          (this.archive == "archive" ||
            !this.permissionPageAccessArry.includes(
              PermissionVariables.VendorEdit
            )) &&
          this.loginUserRole != "Admin"
            ? true
            : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: vendorStatus,
      },
      {
        headerName: "Edit",
        field: "",
        filter: false,
        width: 150,
        sortable: false,
        hide:
          (this.archive == "archive" ||
            !this.permissionPageAccessArry.includes(
              PermissionVariables.VendorEdit
            )) &&
          this.loginUserRole != "Admin"
            ? true
            : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: editVendor,
      },

      {
        headerName: this.archive == "archive" ? "Unarchive" : "Archive",
        field: this.archive ? "1" : "4",
        filter: false,
        hide:
          !this.permissionPageAccessArry.includes(
            PermissionVariables.VendorArchive
          ) && this.loginUserRole != "Admin"
            ? true
            : false,
        sortable: false,
        width: this.archive ? 160 : 150,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: vendorArchive,
      },
    ];
    // this.setColums();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getWorkTypeList(val);
          return val;
        } else return 1;
        // this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    getSelectedRows() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      const selectedDataStringPresentation = selectedData
        .map((node) => node.make + " " + node.model)
        .join(", ");
    },
    // updateSearchQuery(val) {
    //   this.gridApi.setQuickFilter(val);
    // },
    syncVendor() {
      this.$vs.loading();
      axios
        .get(`${BASEURL}/v2/tally/syncVoucherToTally`)
        .then((response) => {
          this.$vs.notify({
            // title: "Duplicate entries have not been imported.",
            title: "Updated!",
            text: "Sync Successfully",
            // time:8000,
            time: 2000,
            iconPack: "feather",
            icon: "check_box",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    onSelectTallySyncOption: function (syncType) {
      this.selectedTallyOption = syncType;

      let payload = {
        limit: this.paginationPageSize,
        type: 0,
        page_no: this.currentPage,
        tallySync: syncType,
      };
      // let pID = this.project_value.project_id;
      ContractService.getContractVendorNew(payload)
        .then((response) => {
          const { data } = response;
          if (!data.err || data.Status == true) {
            this.vendorsList = data.data;
            this.totalPages = data.pagination.last_page;
            this.autoSizeAll();
          } else {
            this.$vs.notify({
              title: "No Data found for the Request",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }

          if (data.hasOwnProperty("tally_isActive")) {
            this.showTally = data.tally_isActive;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateSearchQuery(val) {
      SearchService.getAllSerchResultV2(val, "contractVendor", null)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.vendorsList = data.data;
          } else {
            this.vendorsList = [];
            // this.$vs.notify({
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning",
            // });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getWorkTypeList: function (currentPage) {
      let filter = {
        page: currentPage,
      };
      if (this.archive == "archive") {
        if (this.vendorsList.length == 1) {
          this.vendorsList = [];
        }
        filter["archive"] = true;
      }
      ContractService.getContractVendorNew(filter)
        .then((response) => {
          const { data } = response;
          if (!data.err) {
            this.vendorsList = data.data;
            this.totalPages = data.pagination.last_page;
            // if (screen.width > 1200) {
            //   this.gridApi.sizeColumnsToFit();
            // }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    eventBus.$on("selectedVendor", (e) => {
      let arrIndex = this.selectedVendorList.indexOf(e);
      if (arrIndex >= 0) {
        this.selectedVendorList.splice(arrIndex, 1);
      } else {
        this.selectedVendorList.push(e);
      }
      // this.getWorkTypeList(this.currentPage);
    });
    // if (this.checkPermission(["designation", "GET"])) {
    //   this.getWorkTypeList(this.currentPage);
    // }
    this.getWorkTypeList(this.currentPage);
    this.gridApi = this.gridOptions.api;
    console.log("ListType", this.listType);
  },
};
</script>
